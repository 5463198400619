<template>
  <b-modal
    id="upsert-floor-modal"
    :on-enter-key-press="
      (idnex, floor) => {
        upsertFloor(index, floor)
      }
    "
    hide-footer
  >
    <template v-slot:modal-title>
      <strong>{{ $t('edit') }} {{ $t('floor') }}</strong>
    </template>
    <div class="d-block text-center">
      <div class="row">
        <div class="col-6 text-left">
          <div class="form-group">
            <label>{{ $t('floor') }} {{ $t('name') }}:</label>
            <input
              v-model="floor.name"
              type="text"
              class="form-control"
            />
            <b-form-invalid-feedback v-if="errors.name">
              {{ errors['name'] }}
            </b-form-invalid-feedback>
          </div>
        </div>
        <div v-if="isDashboardAdmin" class="col-6 text-left">
          <div class="form-group">
            <label>{{ $t('svgSnippet') }}:</label>
            <input
              v-model="floor.svgSnippet"
              type="text"
              class="form-control"
            />
            <b-form-invalid-feedback v-if="errors.svgSnippet">
              {{ errors['svgSnippet'] }}
            </b-form-invalid-feedback>
          </div>
        </div>
      </div>
      <p class="text-left">
        {{ $t('floorRestrictions') }}
      </p>
      <div class="row">
        <div class="col-6 text-left">
          <label
            >{{ $t('organisation-group') }}:
            <span
              class="text-success cursor-pointer"
              @click="addGroupField(floor.id, 'edit')"
            >
              <font-awesome-icon icon="plus" /> </span
          ></label>
        </div>
        <div class="col-6 text-left">
          <label
            >{{ $t('organisation-dept') }}:
            <span
              class="text-success cursor-pointer"
              @click="addDepartmentField(floor.id, 'edit')"
            >
              <font-awesome-icon icon="plus" /> </span
          ></label>
        </div>
      </div>
      <div class="row">
        <div class="col-6 text-left">
          <div
            v-for="(group, uniqueKey) in floor.groups"
            :key="uniqueKey"
            class="row align-items-center"
          >
            <div class="col-10 text-left">
              <div class="form-group">
                <b-form-input
                  v-model="group.group"
                  type="text"
                  class="form-control"
                  @blur="validateForm"
                />
              </div>
            </div>
            <div class="col-2 text-left">
              <div class="form-group">
                <span
                  class="text-danger cursor-pointer"
                  @click="removeGroupField(uniqueKey)"
                >
                  <font-awesome-icon icon="times" />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 text-left">
          <div
            v-for="(department, key) in floor.departments"
            :key="key"
            class="row align-items-center"
          >
            <div class="col-10 text-left">
              <div class="form-group">
                <b-form-input
                  v-model="department.department"
                  type="text"
                  class="form-control"
                  @blur="validateForm"
                />
              </div>
            </div>
            <div class="col-2 text-left">
              <div class="form-group">
                <span
                  class="text-danger cursor-pointer"
                  @click="removeDepartmentField(key)"
                >
                  <font-awesome-icon icon="times" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer
      class="d-flex align-items-center justify-content-end mt-1"
    >
      <b-button
        variant="danger"
        @click="$bvModal.hide('upsert-floor-modal')"
      >
        {{ $t('cancel') }}
      </b-button>
      <b-button class="ml-1" primary @click="upsertFloor(floor)">
        {{ $t('save') }}
      </b-button>
    </footer>
  </b-modal>
</template>

<script>
import {
  SET_ALERT,
  SET_FLOORS,
  SET_RESTRICTED_FLOORS
} from '@/store/modules/common/mutationTypes'
import OkkuApi from '@/services/OkkuApi'
import { mapGetters } from 'vuex'

import { uuidv4 } from '@/services/Helper'
import _isEmpty from 'lodash/isEmpty'
import BootstrapModalHOC from '@/components/hocs/BootstrapModal'

export default {
  name: 'UpsertFloorModal',
  components: {
    'b-modal': BootstrapModalHOC
  },
  props: {
    floor: {
      type: Object,
      required: true,
      default: () => {}
    },
    floors: {
      type: Array,
      required: true,
      default: () => []
    },
    isDashboardAdmin: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data() {
    return {
      currentIndex: null,
      floorsChanged: [],
      newFloorName: '',
      newFloorSvg: '',
      changesDone: false,
      floorRestrictions: [{ group: '', department: '' }],
      floorGroups: [],
      floorDepartments: [],
      mode: null,
      selectedFloor: {},
      errors: {}
    }
  },
  computed: {
    isEdit() {
      return _isEmpty(this.selectedFloor)
    },
    ...mapGetters('common', ['isCustomerAdmin'])
  },
  methods: {
    async refreshFloors() {
      await OkkuApi.getFloors().then(floors => {
        this.$store.commit(`common/${SET_RESTRICTED_FLOORS}`, floors)
        this.$store.commit(`common/${SET_FLOORS}`, floors)
      })
    },
    addGroupField(floorId = null) {
      if (this.isEdit && floorId) {
        this.floors.forEach(floor => {
          if (floor.id === floorId) {
            floor.groups.push({ group: '' })
          }
        })
      } else {
        this.floor.groups.push({ group: '' })
      }
    },
    removeGroupField(index) {
      this.floor.groups.splice(index, 1)
    },
    addDepartmentField(floorId = null) {
      if (this.isEdit && floorId) {
        this.floors.forEach(floor => {
          if (floor.id === floorId) {
            floor.departments.push({ department: '' })
          }
        })
      } else {
        this.floor.departments.push({ department: '' })
      }
    },
    removeDepartmentField(index) {
      this.floor.departments.splice(index, 1)
    },
    validateForm() {
      this.floorRestrictions.forEach(restriction => {
        if (
          (restriction.group !== '' &&
            restriction.department === '') ||
          (restriction.group === '' && restriction.department !== '')
        ) {
          this.isValidInfo = false
        } else {
          this.isValidInfo = true
        }
      })
    },
    async upsertFloor(data) {
      if (!this.validateFormData(data)) {
        return
      }
      this.errors = {}
      this.$bvModal.hide('upsert-floor-modal')
      const groups = []

      data.groups.forEach(item => {
        if (item.group !== '') {
          groups.push(item.group)
        }
      })
      const departments = []
      data.departments.forEach(item => {
        if (item.department !== '') {
          departments.push(item.department)
        }
      })
      data.groups = groups
      data.departments = departments
      if (!data.id) {
        data.id = uuidv4()
        data.edit = false
      } else {
        data.edit = true
      }
      // Edit api call
      OkkuApi.upsertFloorsWithRestrictions(data)
        .then(async response => {
          await this.refreshFloors()
          this.$store.commit(`common/${SET_ALERT}`, {
            seconds: 3,
            text: this.$t('changesSaved'),
            variant: 'success'
          })
        })
        .catch(error => {
          let { message } = error
          if (error.response && error.response.data) {
            message = error.response.data
          }
          this.$store.commit(`common/${SET_ALERT}`, {
            seconds: 3,
            text: message,
            variant: 'danger'
          })
        })
    },
    validateFormData(data) {
      if (!data.name) {
        this.errors = {
          name: 'Please enter floor name'
        }
        return false
      }
      if (!data.svgSnippet && !this.isCustomerAdmin) {
        this.errors = {
          svgSnippet: 'Please add svg snippet'
        }
        return false
      }
      return true
    }
  }
}
</script>

<style lang="scss">
#upsert-floor-modal {
  .invalid-feedback {
    display: block;
  }
}
</style>
