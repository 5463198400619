<template>
  <div class="page-container">
    <section class="section-settings">
      <div class="container">
        <div class="row justify-content-md-center">
          <div class="col-lg-12 col-sm-12">
            <alert />
            <FloorsTable @updateChanges="updateChanges" />
          </div>
        </div>
      </div>
    </section>
    <b-modal
      id="modal-route"
      :on-enter-key-press="routeProceed"
      hide-footer
      hide-title
    >
      <div class="d-block text-center">
        <p>{{ $t('changesNotSaved') }}</p>
        <b-button
          variant="danger"
          class="mt-1"
          @click="$bvModal.hide('modal-route')"
        >
          {{ $t('no') }}
        </b-button>
        <b-button class="mt-1" primary @click="routeProceed">
          {{ $t('yes') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Alert from '@/components/common/Alert'
import FloorsTable from '@/components/common/FloorsTable'
import BootstrapModalHOC from '@/components/hocs/BootstrapModal'

export default {
  name: 'FloorsPage',
  components: {
    FloorsTable,
    Alert,
    'b-modal': BootstrapModalHOC
  },
  data() {
    return {
      changesDone: false,
      nextPage: ''
    }
  },
  beforeRouteLeave(to, from, next) {
    if (!this.changesDone) {
      next()
    } else {
      next(false)
      this.nextPage = to.name
      this.$bvModal.show('modal-route')
    }
  },
  methods: {
    updateChanges(args) {
      this.changesDone = args
    },
    routeProceed() {
      this.changesDone = false
      this.$router.push({ name: this.nextPage }, () => {})
    }
  }
}
</script>
