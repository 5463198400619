<template>
  <div>
    <div class="row pb-4  df">
      <h2 class="col m-1">{{ $t('floors') }}</h2>
      <div
        v-if="isDashboardAdmin"
        class="mt-2 mb-2 mr-5 btn btn-sm  btn-secondary d-lg-none"
        @click="showUpsertFloorModal()"
      >
        + {{ $t('add') }}
      </div>

      <div class="col-md-auto m-1">
        <input
          id="table-filter"
          ref="filter"
          type="text"
          class="form-control"
          :placeholder="$t('filterTable')"
          @keyup="filterTable"
        />
      </div>
    </div>
    <form class="table-responsive" @submit.prevent="processForm">
      <table
        ref="table"
        class="table-style table-settings table_sort"
      >
        <thead>
          <tr>
            <th>{{ $t('floor') }}</th>
            <th v-if="isDashboardAdmin">
              {{ $t('svgSnippet') }}
            </th>
            <th>{{ $t('departments') }}</th>
            <th>{{ $t('groups') }}</th>
            <th>
              <span
                v-if="isDashboardAdmin"
                class="btn btn-secondary"
                @click="showUpsertFloorModal()"
                >+ {{ $t('add') }}</span
              >
            </th>
          </tr>
        </thead>

        <draggable v-model="floors" draggable="tr" :element="'tbody'">
          <template v-for="(floor, index) in floors">
            <tr :key="index" :data-val="floor.name">
              <td :data-label="$t('floor') + ':'">
                {{ floor.name }}
              </td>
              <td
                v-if="isDashboardAdmin"
                :data-label="$t('svgSnippet') + ':'"
              >
                <div v-b-toggle="'collapse-' + index">
                  {{ floor.svgSnippet.substring(0, 80) }}...
                </div>
                <b-collapse :id="'collapse-' + index" class="mt-2">
                  <textarea
                    v-model="floor.svgSnippet"
                    rows="20"
                    cols="80"
                    readonly
                  />
                </b-collapse>
              </td>
              <td>
                {{ floor.newDepartments }}
              </td>
              <td>
                {{ floor.newGroups }}
              </td>
              <td data-label>
                <span
                  class="link-edit text-primary"
                  @click="showEditFloor(floor)"
                >
                  <font-awesome-icon icon="edit" />Edit
                </span>
                |
                <span
                  class="link-remove"
                  @click="
                    ;(currentIndex = index),
                      $bvModal.show('modal-remove')
                  "
                >
                  <font-awesome-icon icon="times" />Remove
                </span>
              </td>
            </tr>
          </template>
          <div v-if="!floors.length">
            <tr>
              <td>
                <strong>{{ $t('noRecordsFound') }}</strong>
              </td>
            </tr>
          </div>
        </draggable>
      </table>
      <div v-if="floors.length" class="editdesk-container--foot">
        <button type="submit" class="btn btn-primary btn-lg">
          {{ $t('save') }}
        </button>
      </div>
    </form>
    <b-modal
      id="modal-remove"
      :on-enter-key-press="() => removeFloor(currentIndex)"
      hide-footer
    >
      <template v-slot:modal-title>
        <strong>{{ $t('remove') }}</strong>
      </template>
      <div class="d-block text-center">
        <p>{{ $t('deleteConfirmation') }}</p>
        <b-button
          variant="danger"
          class="mt-1"
          @click="$bvModal.hide('modal-remove')"
        >
          {{ $t('no') }}
        </b-button>
        <b-button
          class="mt-1 ml-1"
          primary
          @click="removeFloor(currentIndex)"
        >
          {{ $t('yes') }}
        </b-button>
      </div>
    </b-modal>
    <upsert-floor-modal
      :floor="selectedFloor"
      :floors="floors"
      :is-dashboard-admin="isDashboardAdmin"
    />
  </div>
</template>
<script>
import _cloneDeep from 'lodash/cloneDeep'
import draggable from 'vuedraggable'
import { mapState, mapGetters } from 'vuex'

import OkkuApi from '@/services/OkkuApi'
import { filterTableMixin } from '@/mixins/mixins'
import {
  SET_ALERT,
  SET_FLOORS,
  SET_RESTRICTED_FLOORS
} from '@/store/modules/common/mutationTypes'

import UpsertFloorModal from '@/components/modals/UpsertFloorModal'
import BootstrapModalHOC from '@/components/hocs/BootstrapModal'

export default {
  name: 'FloorsTable',
  components: {
    draggable,
    'b-modal': BootstrapModalHOC,
    UpsertFloorModal
  },
  mixins: [filterTableMixin],
  data() {
    return {
      currentIndex: null,
      floorsChanged: [],
      newFloorName: '',
      newFloorSvg: '',
      changesDone: false,
      floors: [],
      floorRestrictions: [{ group: '', department: '' }],
      floorGroups: [{ group: '' }],
      floorDepartments: [{ department: '' }],
      mode: null,
      selectedFloor: {}
    }
  },
  computed: {
    ...mapState('common', {
      isDashboardAdmin: '$isDashboardAdmin',
      storeFloors: 'restrictedFloors'
    }),
    ...mapGetters('common', ['isAdmin'])
  },
  watch: {
    storeFloors() {
      const floors = _cloneDeep(this.storeFloors)
      floors.forEach(floor => {
        if (!floor.svgSnippet) {
          floor.svgSnippet = ''
        }
        floor.newDepartments = floor.departments
          .map(dept => dept.department)
          .toString()
        floor.newGroups = floor.groups
          .map(group => group.group)
          .toString()
      })
      this.floors = floors
    },
    changesDone() {
      this.$emit('updateChanges', this.changesDone)
    }
  },
  mounted() {
    this.floors = _cloneDeep(this.storeFloors)
  },
  async created() {
    await this.refreshFloors()
  },
  methods: {
    async processForm() {
      const floors = this.floors.map((floor, index) => ({
        ...floor,
        orderId: index
      }))
      OkkuApi.upsertFloors(floors)
        .then(async response => {
          this.$store.commit(`common/${SET_ALERT}`, {
            seconds: 3,
            text: this.$t('changesSaved'),
            variant: 'success'
          })
          this.changesDone = false
          await this.refreshFloors()
        })
        .catch(error => {
          this.$store.commit(`common/${SET_ALERT}`, {
            seconds: 3,
            text: error.message,
            variant: 'danger'
          })
        })
    },
    async refreshFloors() {
      await OkkuApi.getFloors().then(floors => {
        this.$store.commit(`common/${SET_RESTRICTED_FLOORS}`, floors)
        this.$store.commit(`common/${SET_FLOORS}`, floors)
      })
    },
    removeFloor(index) {
      OkkuApi.deleteFloor(this.floors[index].id)
        .then(response => {
          this.floors.splice(index, 1)
          this.$store.commit(
            `common/${SET_RESTRICTED_FLOORS}`,
            this.floors
          )
          this.$store.commit(`common/${SET_FLOORS}`, this.floors)
          this.$store.commit(`common/${SET_ALERT}`, {
            seconds: 3,
            text: this.$t('changesSaved'),
            variant: 'success'
          })
        })
        .catch(error => {
          this.$store.commit(`common/${SET_ALERT}`, {
            seconds: 3,
            text: error.message,
            variant: 'danger'
          })
        })
      this.$bvModal.hide('modal-remove')
    },
    showUpsertFloorModal() {
      this.selectedFloor = {
        groups: [],
        departments: []
      }
      this.$bvModal.show('upsert-floor-modal')
    },
    showEditFloor(floor) {
      this.selectedFloor = floor
      if (!floor.groups.length) {
        floor.groups = []
        floor.groups.push()
      }
      if (!floor.departments.length) {
        floor.departments = []
        floor.departments.push()
      }
      this.$bvModal.show('upsert-floor-modal')
    }
  }
}
</script>
<style lang="scss" scoped>
.tip-icon::v-deep {
  .fa-question-circle {
    font-size: 16px;
    margin-right: 5px;
    cursor: pointer;
  }
}
table {
  input {
    display: inline-block;
    vertical-align: middle;
  }
}
</style>
